.project {
  padding: 32px 48px;
  @media screen and (max-width: 768px) {
    padding: 16px 24px;
  }
  &-title {
    @include subtitle1($light-blue);
  }
  &-subtitle {
    @include subtitle2($primary-white);
  }
  &-section-title {
    @include body1($primary-white);
  }
  &-ui &-content {
    width: 100%;
    height: 285px;
    position: relative;
    @media screen and (max-width: 576px) {
      height: fit-content;
      padding-bottom: 72px;
    }
  }
  &-mobile &-content {
    width: 100%;
    // height: 285px;
    height: 100%;
    position: relative;
    @media screen and (max-width: 576px) {
      padding-bottom: 72px;
    }
  }
  &-name {
    @include body2($primary-white);
  }
  &-desc {
    @include body3($primary-white);
    text-align: justify;
  }
  &-web &-desc,
  &-mobile &-desc {
    min-height: 80px;
    @media screen and (max-width: 768px) {
      min-height: fit-content;
    }
  }
  &-category {
    @include body3();
    box-shadow: $shadow-inner;
    border-radius: 16px;
    padding: 4px 12px;
    width: fit-content;
  }
  &-link {
    position: absolute;
    bottom: 0;
    @include body2($primary-white);
    @media screen and (max-width: 576px) {
      bottom: 24px;
    }
  }
}
