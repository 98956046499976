@mixin heading2($color: $primary-white) {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.5px;

  color: $color;
  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
}

@mixin heading4($color: $primary-white) {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.25px;

  color: $color;
}

@mixin heading5($color: $primary-white) {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0px;

  color: $color;
}

@mixin heading6($color: $primary-white) {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;

  color: $color;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin subtitle1($color: $primary-white) {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;

  color: $color;
}

@mixin subtitle2($color: $primary-white) {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;

  color: $color;
}

@mixin body1($color: $primary-white) {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;

  color: $color;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin body2($color: $primary-white) {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;

  color: $color;
}

@mixin body3($color: $primary-white) {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;

  color: $color;
}
