.contacts {
  padding: 32px 48px;
  @media screen and (max-width: 768px) {
    padding: 16px 24px;
  }
  .section-title {
    @include subtitle1($light-blue);
  }
  .address {
    margin-top: 32px;
    position: relative;
    &-box {
      width: 100%;
      height: 180px;
    }
    &-content {
      @include heading6();
    }
    &-icon {
      position: absolute;
      top: -26px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .contact {
    margin-top: -26px;
    position: relative;
    z-index: 2;
    &-text {
      @include body3();
    }
  }
  .socials {
    &-text {
      @include body3();
    }
  }
}
