@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$primary-blue: #0d0d2b;
$darker-blue: #191952;
$light-blue: #a6bcff;
$primary-tosca: #d3deff;
$primary-white: #ffffff;

$shadow-dark: rgba(0, 0, 0, 0.25);
$shadow-light: rgba(37, 37, 64, 0.25);

$shadow-outer: 3px 3px 3px $shadow-dark, -3px -3px 3px $shadow-light;
$shadow-inner: inset 3px 3px 3px $shadow-dark, inset -3px -3px 3px $shadow-light;
