/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
a:hover,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  color: inherit;
  text-decoration: none;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.box-outer {
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.25), inset -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 16px;
  width: 100%;
  padding: 8px;
}
.box-outer > .box-inner {
  background-color: #0d0d2b;
  width: 100%;
  height: 100%;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25), -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 12px;
}

.circle-outer {
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.25), inset -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  padding: 8px;
}
.circle-outer > .circle-inner {
  background-color: #0d0d2b;
  width: 100%;
  height: 100%;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25), -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 50%;
}

.outer-box {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25), -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  height: 100%;
}

.inner-full {
  padding: 8px 24px;
  background-color: #0d0d2b;
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.25), inset -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 48px;
}

.outer-full {
  width: 100%;
  padding: 8px 24px;
  background-color: #0d0d2b;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25), -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 48px;
  transition: all 0.3s ease-in-out;
}
.outer-full:hover {
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.25), inset -3px -3px 3px rgba(37, 37, 64, 0.25);
}

.outer-fit {
  width: fit-content;
  padding: 8px 24px;
  background-color: #0d0d2b;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25), -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 48px;
  transition: all 0.3s ease-in-out;
}
.outer-fit:hover {
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.25), inset -3px -3px 3px rgba(37, 37, 64, 0.25);
}

.outer-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25), -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 20px;
}
.outer-icon:hover {
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.25), inset -3px -3px 3px rgba(37, 37, 64, 0.25);
}

.inner-icon {
  background-color: #0d0d2b;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.25), inset -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 20px;
}

.inner-big-icon {
  background-color: #0d0d2b;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.25), inset -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 30px;
}

.outer-small-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25), -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 20px;
}

.long-divider {
  margin: 54px auto;
  width: 100px;
  height: 4px;
  background-color: #0d0d2b;
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.25), inset -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 4px;
  position: relative;
}
.long-divider::after {
  background-color: #a6bcff;
  border-radius: 4px;
  position: absolute;
  width: 50px;
  height: 100%;
  content: "";
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.short-divider {
  margin: 8px 0;
  width: 50px;
  height: 4px;
  background-color: #0d0d2b;
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.25), inset -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 4px;
  position: relative;
}
.short-divider::after {
  background-color: #a6bcff;
  border-radius: 4px;
  position: absolute;
  width: 25px;
  height: 100%;
  content: "";
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a6bcff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d3deff;
}

body {
  background-color: #0d0d2b;
  color: #ffffff;
  min-height: 100vh;
  position: relative;
  overflow-y: overlay;
}
body #bg-component {
  overflow: hidden;
  position: fixed;
  z-index: -1;
  height: 100vh;
  width: 100vw;
}
body #bg-component .top-left {
  position: absolute;
  top: 0;
  left: 0;
}
body #bg-component .top-middle {
  position: absolute;
  top: 0;
  left: 15px;
}
body #bg-component .top-right {
  position: absolute;
  top: 0;
  right: 0;
}
body #bg-component .bottom-left {
  position: absolute;
  bottom: -100px;
  left: 0;
}
body #bg-component .bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

#menu {
  min-height: 100vh;
  position: sticky;
  top: 0;
  padding: 24px 0;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 992px) {
  #menu {
    min-height: fit-content;
    margin: 24px auto;
  }
}
#menu .menu {
  padding: 32px 24px;
}
@media screen and (max-width: 992px) {
  #menu .menu {
    padding: 24px 16px;
  }
}
#menu .menu-profile {
  max-width: 200px;
  max-height: 200px;
  margin: auto;
}
#menu .menu-fullname {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0px;
  color: #a6bcff;
}
#menu .menu-jobs {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #a6bcff;
}
#menu .menu-list {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  #menu .menu-list {
    font-size: 14px;
    line-height: 18px;
  }
}

#content {
  min-height: 100vh;
  padding: 20px 0;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 992px) {
  #content {
    min-height: fit-content;
    margin: 24px auto;
  }
}

.home {
  padding: 64px 32px 32px;
}
@media screen and (max-width: 992px) {
  .home {
    padding: 32px 16px 24px;
  }
}
.home-logo {
  width: 100px;
}
.home-title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.5px;
  color: #d3deff;
}
@media screen and (max-width: 768px) {
  .home-title {
    font-size: 24px;
    line-height: 28px;
  }
}
.home-desc {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #a6bcff;
}
@media screen and (max-width: 768px) {
  .home-desc {
    font-size: 14px;
    line-height: 18px;
  }
}
.home-desc .desc-highlight {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #ffffff;
  display: inline-block;
  padding: 4px 16px;
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.25), inset -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 16px;
}
@media screen and (max-width: 768px) {
  .home-desc .desc-highlight {
    font-size: 14px;
    line-height: 18px;
  }
}
.home-button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .home-button {
    font-size: 14px;
    line-height: 18px;
  }
}

.about {
  padding: 32px 48px;
}
@media screen and (max-width: 768px) {
  .about {
    padding: 16px 24px;
  }
}
.about-section {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #a6bcff;
}
.about-title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.25px;
  color: #d3deff;
}
.about-jobs {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #a6bcff;
}
.about-content {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #a6bcff;
}
@media screen and (max-width: 768px) {
  .about-content {
    font-size: 14px;
    line-height: 18px;
  }
}
.about-content .content-highlight {
  color: #ffffff;
}

.experiences {
  padding: 32px 48px;
}
@media screen and (max-width: 768px) {
  .experiences {
    padding: 16px 24px;
  }
}
.experiences .skills-section {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #a6bcff;
}
.experiences .skills-list {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .experiences .skills-list {
    font-size: 14px;
    line-height: 18px;
  }
}
.experiences .experience-section {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #a6bcff;
}
.experiences .experience .section-content {
  position: relative;
  margin-left: 18.5px;
}
.experiences .experience .section-content::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 3px;
  top: 0;
  left: 0;
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.25), inset -3px -3px 3px rgba(37, 37, 64, 0.25);
  background-color: #0d0d2b;
}
.experiences .experience .section-content .content-list {
  padding-left: 16px;
  position: relative;
}
.experiences .experience .section-content .content-list::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 16px;
  width: 3px;
  background-color: #a6bcff;
  border-radius: 4px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25), -3px -3px 3px rgba(37, 37, 64, 0.25);
}
.experiences .experience .section-content .content-list .list-title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #ffffff;
  text-align: justify;
}
.experiences .experience .section-content .content-list .list-date {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #d3deff;
}
.experiences .experience .section-content .content-list .list-date .present-highlight {
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.25), inset -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 16px;
  padding: 4px 12px;
}
.experiences .experience .section-content .content-list .list-place {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #a6bcff;
}

.project {
  padding: 32px 48px;
}
@media screen and (max-width: 768px) {
  .project {
    padding: 16px 24px;
  }
}
.project-title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #a6bcff;
}
.project-subtitle {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #ffffff;
}
.project-section-title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .project-section-title {
    font-size: 14px;
    line-height: 18px;
  }
}
.project-ui .project-content {
  width: 100%;
  height: 285px;
  position: relative;
}
@media screen and (max-width: 576px) {
  .project-ui .project-content {
    height: fit-content;
    padding-bottom: 72px;
  }
}
.project-mobile .project-content {
  width: 100%;
  height: 100%;
  position: relative;
}
@media screen and (max-width: 576px) {
  .project-mobile .project-content {
    padding-bottom: 72px;
  }
}
.project-name {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #ffffff;
}
.project-desc {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #ffffff;
  text-align: justify;
}
.project-web .project-desc, .project-mobile .project-desc {
  min-height: 80px;
}
@media screen and (max-width: 768px) {
  .project-web .project-desc, .project-mobile .project-desc {
    min-height: fit-content;
  }
}
.project-category {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #ffffff;
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.25), inset -3px -3px 3px rgba(37, 37, 64, 0.25);
  border-radius: 16px;
  padding: 4px 12px;
  width: fit-content;
}
.project-link {
  position: absolute;
  bottom: 0;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #ffffff;
}
@media screen and (max-width: 576px) {
  .project-link {
    bottom: 24px;
  }
}

.contacts {
  padding: 32px 48px;
}
@media screen and (max-width: 768px) {
  .contacts {
    padding: 16px 24px;
  }
}
.contacts .section-title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #a6bcff;
}
.contacts .address {
  margin-top: 32px;
  position: relative;
}
.contacts .address-box {
  width: 100%;
  height: 180px;
}
.contacts .address-content {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .contacts .address-content {
    font-size: 14px;
    line-height: 18px;
  }
}
.contacts .address-icon {
  position: absolute;
  top: -26px;
  left: 50%;
  transform: translateX(-50%);
}
.contacts .contact {
  margin-top: -26px;
  position: relative;
  z-index: 2;
}
.contacts .contact-text {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #ffffff;
}
.contacts .socials-text {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #ffffff;
}